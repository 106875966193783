<template>
  <div class="w-100 ma-0 pa-2 pl-3 sticky">
    <div class="d-flex justify-space-between pa-0">
      <GraphToggler />
      <v-btn variant="elevated" class="text-primary" @click="close()">
        <template #append>
          <v-icon>mdi-close</v-icon>
        </template>
        Close
      </v-btn>
    </div>
    <div>
      <GraphWeightBar :weight-percentage="weightPercentage" class="mb-5" />
      <div class="w-100">
        <v-text-field v-model="searchTerm" label="Search" prepend-inner-icon="mdi-magnify" />
      </div>
    </div>
  </div>
  <v-card tile opacity="100">
    <v-card-text>
      <v-row>
        <v-col cols="3" class="left-menu">
          <v-list flat cols="4">
            <div class="text-primary text-h5 mb-5">SETTINGS</div>
            <v-list-item-title class="text-primary">TIME RANGE</v-list-item-title>
            <v-list-item>
              <PeriodPicker @select="changePeriod($event)" />
            </v-list-item>
          </v-list>

          <v-divider class="mt-2 mb-2" />

          <CategorySelection />

          <v-divider class="mt-2 mb-2" />

          <v-list flat cols="4">
            <v-list-item-title class="text-primary">CREATE CUSTOM GROUP</v-list-item-title>
            <v-list-item>
              <CreateCustomGroup scope="private" />
            </v-list-item>
          </v-list>

          <v-divider class="mt-2 mb-2" />

          <v-list flat cols="4">
            <v-list-item-title class="text-primary">CUSTOM GROUPS</v-list-item-title>
            <CustomGroupsList :closable="hasAdminRights" scope="global" :draggable="hasAdminRights" />
            <CustomGroupsList :closable="true" scope="private" :draggable="true" />
          </v-list>

          <v-list v-if="hasAdminRights" flat cols="4">
            <v-list-item-title class="text-primary">CREATE GLOBAL CUSTOM GROUP</v-list-item-title>
            <v-list-item>
              <CreateCustomGroup scope="global" />
            </v-list-item>
          </v-list>

          <v-divider class="mt-2 mb-2" />

          <v-list>
            <v-list-item>
              <v-btn variant="outlined" color="primary" @click="clearSelection()">Clear selections</v-btn>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="9" class="d-flex align-start flex-column flex-wrap pa-0">
          <GraphSelectionByCategory :search-term="searchTerm" />
        </v-col>
      </v-row>

      <v-row v-show="showBottomToolbar">
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            class="text-primary justify-center bottom-toolbar"
            variant="elevated"
            rounded="xl"
            @click="scrollTop()"
          >
            Back to Top
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import dayjs from 'dayjs'

  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore, TroubleshooterStore } from '#stores'

  @Component({ emits: ['close'] })
  class GraphSelectionContainer extends Vue {
    private troubleshooterStore = new TroubleshooterStore()
    private appStore = new AppStore()

    public searchTerm: string = ''
    public showBottomToolbar: boolean = false

    public mounted() {
      window.addEventListener('keydown', this.handleKeydown)
      window.addEventListener('scroll', this.handleScrollEvent)
    }

    public beforeUnmount() {
      window.removeEventListener('keydown', this.handleKeydown)
      window.removeEventListener('scroll', this.handleScrollEvent)
    }

    public handleKeydown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        this.close()
      }
    }

    public handleScrollEvent() {
      this.showBottomToolbar = scrollY > 0
    }

    public close() {
      this.$emit('close')
    }

    public changePeriod(value: number) {
      const startDate = dayjs().add(value, 'days')
      const endDate = dayjs()

      this.troubleshooterStore.setStartDate(startDate)
      this.troubleshooterStore.setEndDate(endDate)
    }

    public clearSelection() {
      this.troubleshooterStore.clearSelection()
    }

    public get weightPercentage() {
      return this.troubleshooterStore.weightPercentage
    }

    public scrollTop() {
      scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    public get hasAdminRights() {
      return this.appStore.hasAdminRights
    }
  }

  export default toNative(GraphSelectionContainer)
</script>

<style scoped lang="scss">
  .sticky {
    position: sticky;
    top: 125px;
    background-color: rgba(var(--v-theme-surface), 1);
    z-index: 1000;
  }
  .bottom-toolbar {
    position: fixed;
    bottom: 50px;
    background-color: rgba(var(--v-theme-surface), 1);
    z-index: 1000;
  }
</style>
