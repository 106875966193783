<template>
  <v-expansion-panels v-model="openPanels" multiple>
    <v-expansion-panel
      v-for="category in categories"
      :key="category.name"
      :value="category.name"
      data-cy="troubleshooter-graphs-by-category"
      elevation="0"
      :static="true"
      class="ma-0 pa-0"
    >
      <v-expansion-panel-title :id="category.name" class="text-primary text-subtitle-2 pa-1">
        {{ category.name }}
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row dense class="pa-0">
          <span v-if="filteredGraphs.get(category.name)?.length === 0">All results were filtered out</span>
          <v-col
            v-for="graph in filteredGraphs.get(category.name)"
            :key="graph.name"
            cols="12"
            md="3"
            sm="5"
            class="pa-0 d-flex align-center"
          >
            <v-col class="pa-0">
              <v-checkbox
                v-if="graph.name"
                :disabled="!graph.isSelected && !canSelect(graph).allow"
                :model-value="graph.isSelected"
                data-cy="troubleshooter-graphs-by-category-checkbox"
                class="pa-0 ma-0"
                hide-details
                @change="graph.isSelected ? deSelectGraph(graph) : selectGraph(graph)"
              >
                <template #label>
                  {{ graph.name }}
                </template>
              </v-checkbox>
              <v-tooltip v-if="!canSelect(graph).allow" activator="parent" location="bottom">
                {{ canSelect(graph).reason }}
              </v-tooltip>
            </v-col>
            <v-col cols="2" class="pa-0">
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <v-icon v-bind="props" size="small" class="mr-2" @click.stop>mdi-information-outline</v-icon>
                </template>
                <div class="tooltip">
                  <div>
                    <strong>Type:</strong>
                    {{ graph.graphType }}
                  </div>
                  <div>
                    <strong>Weight:</strong>
                    {{ graph.weight }}
                  </div>
                  <div>
                    <strong>Description:</strong>
                    {{ graph.description }}
                  </div>
                  <div>
                    <strong>Usage:</strong>
                    {{ graph.usageInfo }}
                  </div>
                </div>
              </v-tooltip>
            </v-col>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { TroubleshooterStore } from '#stores'

  import { Graph, SelectionGrant, TroubleshooterCategory } from '#types/troubleshooter'

  @Component({})
  class GraphSelectionByCategory extends Vue {
    private troubleshooterStore = new TroubleshooterStore()

    @Prop({
      default: '',
    })
    public searchTerm: string = ''
    public openPanels: string[] = this.troubleshooterStore.categories.map((category) => category.name)

    public get selectedCategory(): TroubleshooterCategory | null {
      return this.troubleshooterStore.selectedCategory
    }

    public get categories(): TroubleshooterCategory[] {
      return this.troubleshooterStore.categories
    }

    public get filteredGraphs(): Map<string, Graph[]> {
      const graphIndex = new Map<string, Graph[]>()
      this.categories.forEach((category) => {
        const filteredGraphs = category.graphs.filter((graph) =>
          graph.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
        )
        graphIndex.set(category.name, filteredGraphs)
      })
      return graphIndex
    }

    public canSelect(graph: Graph): SelectionGrant {
      return this.troubleshooterStore.canSelect([graph])
    }

    public selectGraph(graph: Graph) {
      this.troubleshooterStore.selectGraphs([graph])
    }

    public deSelectGraph(graph: Graph) {
      this.troubleshooterStore.deSelectGraphs([graph])
    }

    @Watch('selectedCategory')
    public selectedCategoryChanged(category: TroubleshooterCategory | null) {
      if (category) {
        const found = this.openPanels.find((panel) => panel === category.name)
        if (!found) {
          // Opens the panel
          this.openPanels.push(category.name)
        }

        const htmlElement = document.getElementById(category.name)
        htmlElement?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })

        // Selected category is cleared so that the same category can be re-selected again
        this.troubleshooterStore.setSelectedCategory(null)
      }
    }

    @Watch('searchTerm')
    public setPanelsOpen() {
      this.categories.forEach((category) => {
        if (this.filteredGraphs.has(category.name)) {
          if (!this.openPanels.includes(category.name)) {
            this.openPanels.push(category.name)
          }
        }
      })
    }
  }

  export default toNative(GraphSelectionByCategory)
</script>

<style scoped lang="scss"></style>
