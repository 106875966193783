<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-text-field v-model="customGroupName" data-cy="troubleshooter-create-custom-group-text-field" label="Name" />
  <v-btn
    :disabled="canCreateCustomGroup"
    data-cy="troubleshooter-create-custom-group-button"
    variant="outlined"
    color="primary"
    @click="showCreateDialog = true"
  >
    Create a custom group
  </v-btn>

  <ConfirmationDialog
    v-model="showCreateDialog"
    :close-callback="close"
    :disable-confirm="disableConfirm"
    title="Are you sure you want to create this group?"
    @confirm="createCustomGroup()"
  >
    <v-container>
      <v-row>
        <v-col>
          <strong>Group name:</strong>
          {{ customGroupName }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-chip v-for="graph in selectedGraphs" :key="graph.value" class="mr-2 mb-2">
            {{ graph.name }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row v-if="statusResponse">
        <v-col>
          <v-alert :type="statusResponse.success ? 'success' : 'error'">
            {{ statusResponse.message }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </ConfirmationDialog>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { GroupScope } from '#views/members/constants'

  import { TroubleshooterStore } from '#stores'
  import { CustomGroupStore } from '#stores/customGroups'

  import { CustomGroupResponse } from '#types/troubleshooter'

  @Component({})
  class CreateCustomGroup extends Vue {
    @Prop() public scope: GroupScope = GroupScope.PRIVATE

    public customGroupName: string = ''
    public showCreateDialog = false
    public statusResponse: CustomGroupResponse | null = null
    public disableConfirm = false

    private customGroupStore = new CustomGroupStore()
    private troubleshooterStore = new TroubleshooterStore()

    public async createCustomGroup() {
      this.statusResponse = await this.customGroupStore.createCustomGroup(
        this.customGroupName,
        this.troubleshooterStore.selectedGraphKeys,
        this.scope,
      )
      if (this.statusResponse.success) {
        this.disableConfirm = true
      }
    }

    public close() {
      this.statusResponse = null
      this.customGroupName = ''
      this.disableConfirm = false
    }

    public get canCreateCustomGroup() {
      return this.troubleshooterStore.selectedGraphs && !this.customGroupName.length
    }

    public get selectedGraphs() {
      return this.troubleshooterStore.selectedGraphs
    }
  }

  export default toNative(CreateCustomGroup)
</script>

<style scoped lang="scss"></style>
