import { toRaw } from 'vue'

import { PlotlyFigure } from '#types'

export const EMPTY_GRAPH: PlotlyFigure = {
  data: [],
  layout: {
    annotations: [
      {
        text: 'No data found',
        xref: 'paper',
        yref: 'paper',
        showarrow: false,
        font: {
          size: 26,
        },
      },
    ],
  },
}

/**
 * Combines multiple plotly graphs to single one
 * @param plotData object with multiple graph's data
 * @param axisData object for mapping graph to correct axis
 * @param startDateString
 * @param endDateString
 * @param graphMode
 */
export function combinePlotData(
  plotData: any,
  axisData: any,
  startDateString: string,
  endDateString: string,
  graphMode: string,
): { layout: any; data: any[] } {
  const data: any = {}
  let combinedPlotData: { layout: any; data: any[] } = { layout: {}, data: [] }
  const annotations: any[] = []
  const availableAxisMap: any = {}
  const addedLegendGroups: string[] = []
  let addedAxes: string[] = []
  let yAxisNumber = 0

  for (const key in plotData) {
    if (!plotData[key]?.length) {
      continue
    }

    data[key] = plotData[key].reduce((a: any, b: any) => {
      // Save annotations to a list
      if (b.layout.annotations) {
        annotations.push(...b.layout.annotations)
      }
      return combineTwoPlots(toRaw(a), toRaw(b))
    }, null)

    // Remove duplicate values if there are any
    if (data[key].data.length) {
      data[key].data = removeDuplicateValues(data[key].data)
    }

    // Store the used axes to availableAxisMap
    if (data[key].data.length) {
      if (!availableAxisMap[axisData[key]['name']]) {
        availableAxisMap[axisData[key]['name']] = yAxisNumber > 0 ? 'y' + (yAxisNumber + 1) : 'default'
      }

      // Add yaxis to the layout if not already added
      if (!addedAxes.includes(axisData[key]['name'])) {
        ;[addedAxes, combinedPlotData] = setYAxesToLayout(combinedPlotData, axisData, key, yAxisNumber, addedAxes)
        yAxisNumber++
      }

      processTraceData(data[key].data, addedLegendGroups, graphMode, availableAxisMap, axisData[key]['name'])

      // Combine data and layout
      combinedPlotData = {
        layout: { ...data[key].layout, ...combinedPlotData.layout },
        data: [...combinedPlotData.data, ...data[key].data],
      }

      setBarOffsetGroups(combinedPlotData.data)

      // Set default plot settings
      combinedPlotData = setDefaultSettings(combinedPlotData, startDateString, endDateString)
      // Set annotations
      combinedPlotData.layout.annotations = annotations
    }
  }

  // Return empty graph with a message in case there's no data
  return combinedPlotData.data.length ? combinedPlotData : EMPTY_GRAPH
}

export function combineTwoPlots(a: any, b: any) {
  /**
   * Combine data of two plotly graphs to one
   */
  // Skip if is undefined
  if (!a) {
    return b
  }

  // Check that both have data
  if (a.data.length == 0 || !a.data[0].x) {
    return b
  } else if (b.data.length == 0 || !b.data[0].x) {
    return a
  }

  // Combine data
  if (a.data[0].mode === 'lines') {
    a.data[0].x.push(null)
  }

  a.data = [...a.data, ...b.data]

  if (a.data[0].mode === 'lines') {
    a.data[0].x.push(null)
  }

  // Remove default y-axes
  if (a?.layout?.yaxis) {
    delete a.layout.yaxis
  }

  return a
}

export function removeDuplicateValues(data: any) {
  /**
   * Remove duplicate values from the data
   */

  const uniqueData: string[] = []
  data = data.filter((d: any) => {
    if (!d.x) {
      return false
    } else {
      const rowId = d.x.join('') + d.y.join('')
      const isDuplicate = uniqueData.includes(rowId)
      uniqueData.push(rowId)
      return !isDuplicate
    }
  })
  return data
}

function setDefaultSettings(
  combinedPlotData: { layout: any; data: any[] },
  startDateString: string,
  endDateString: string,
): { layout: any; data: any[] } {
  /**
   * Set default settings for the plotly graph
   */
  //setting spikes to false on creation, only needed for xaxis
  combinedPlotData.layout.xaxis.showspikes = false

  // Set fixed x-axis to always show the selected date-range
  const startDate = new Date(startDateString).setHours(0, 0, 0)
  const endDate = new Date(endDateString).setHours(23, 59, 59)
  combinedPlotData['layout']['xaxis']['autorange'] = false
  combinedPlotData['layout']['xaxis']['range'] = [startDate, endDate]

  return combinedPlotData
}

function setYAxesToLayout(
  combinedPlotData: { layout: any; data: any[] },
  axisData: any,
  key: string,
  yAxisNumber: number,
  addedAxes: string[],
): [string[], { layout: any; data: any[] }] {
  /**
   * Set y-axes to the plotly graphs layout
   */
  const yAxisKey = yAxisNumber == 0 ? 'yaxis' : 'yaxis' + (yAxisNumber + 1)
  combinedPlotData.layout[yAxisKey] = { ...axisData['common'], ...axisData[key]['axis'] }
  if (yAxisNumber % 2 == 0) {
    combinedPlotData.layout[yAxisKey]['side'] = 'left'
  } else {
    combinedPlotData.layout[yAxisKey]['side'] = 'right'
  }
  if (yAxisNumber > 0) {
    combinedPlotData.layout[yAxisKey]['overlaying'] = 'y'
  }

  if (yAxisNumber > 1) {
    // Set the position of the y-axis when there's more than 2 y-axes
    combinedPlotData.layout[yAxisKey]['position'] = yAxisNumber / 10 + 1
  }

  addedAxes.push(axisData[key]['name'])

  return [addedAxes, combinedPlotData]
}

/**
 * Get the corresponding y-axis for each graph
 * @param graphOptions
 * @param graphs
 */
export function getAxisData(graphOptions: { categories: any; yaxes: any }, graphs: string[]) {
  const graphOptionsFlattened: any = {}

  Object.keys(graphOptions.categories).forEach((key) => {
    const value = graphOptions.categories[key]
    Object.assign(graphOptionsFlattened, value)
  })

  const axisOptions: any = { common: {} }

  for (const graph of graphs) {
    const axis = graphOptionsFlattened[graph]?.yaxis
    axisOptions[graph] = {
      axis: graphOptions.yaxes[axis],
      name: axis,
    }
  }
  // Add common graph data
  if (Object.keys(graphOptions.yaxes).includes('common')) {
    axisOptions.common = graphOptions.yaxes.common
  }
  return axisOptions
}

export function processTraceData(
  traces: any[],
  addedLegendGroups: string[],
  graphMode: string,
  availableAxisMap: any,
  axisName: string,
) {
  for (const trace of traces) {
    // Remove legend from all traces except the first one with given name
    if (addedLegendGroups.includes(trace.name)) {
      trace.showlegend = false
    } else {
      addedLegendGroups.push(trace.name)
    }

    // Add legendgroup to all traces, so they can be controlled from a single legend
    trace.legendgroup = trace.name

    // --- PERFORMANCE ---
    if (graphMode === 'WebGL' && trace.type === 'scatter') {
      // Change scatter to scattergl to improve performance when graphMode is WebGL
      trace.type = 'scattergl'
    }

    // --- AXES ---
    if (availableAxisMap[axisName]) {
      // Set trace to correct y-axis
      trace.yaxis = availableAxisMap[axisName]
    }
  }
}

// Set bar offset groups to avoid overlapping
export function setBarOffsetGroups(data: any[]) {
  let barOffsetGroup = 1
  for (const val of data) {
    if (val.type && val.type === 'bar') {
      val['offsetgroup'] = barOffsetGroup
      barOffsetGroup++
    }
  }
}
