export function addHorizontalLines(plotData: any, horizontalLines: number[] = []): any {
  if (!plotData || !plotData.data) {
    return { shapes: [] }
  }

  const yValues: number[] = []
  for (const d of plotData.data) {
    if (Array.isArray(d.y)) {
      for (const val of d.y) {
        if (typeof val === 'number' && !isNaN(val)) {
          yValues.push(val)
        }
      }
    }
  }

  if (yValues.length === 0) {
    return { shapes: [] }
  }

  const minY = Math.min(...yValues)
  const maxY = Math.max(...yValues)

  const linesToShow = horizontalLines.filter((y: number) => y >= minY && y <= maxY)

  return {
    shapes: linesToShow.map((y) => ({
      type: 'line',
      xref: 'paper',
      yref: 'y',
      x0: 0,
      x1: 1,
      y0: y,
      y1: y,
      line: {
        color: 'red',
        width: 2,
        dash: 'dash',
      },
    })),
  }
}
