import dayjs, { Dayjs } from 'dayjs'

import { Store } from 'pinia-class-component'

import {
  graphDefaultEarliestStartDate,
  graphDefaultLatestEndDate,
  graphMaxTotalWeight,
  graphSingleDayWeight,
} from '#views/members/constants'

import {
  calculateTimeWeightMultiplier,
  calculateTotalWeight,
  calculateWeightDateLimits,
} from '#utils/generic-graph/weights'
import { getFilterWeights, sortByName, toTroubleShooterCategories } from '#utils/troubleshooter/conversions'

import { BaseStore } from '#stores/base'

import { Categories } from '#types'
import { Graph, SelectionGrant, TroubleShooterState, TroubleshooterCategory } from '#types/troubleshooter'

const DEFAULT_DAYS_IN_GRAPH = 14
const SESSION_STORAGE_KEY = 'TroubleshooterSettings'

@Store()
export class TroubleshooterStore extends BaseStore {
  private _categories: TroubleshooterCategory[] = []
  private _startDate: Dayjs = dayjs().subtract(DEFAULT_DAYS_IN_GRAPH, 'days')
  private _endDate: Dayjs = dayjs()
  private _selectedCategory: TroubleshooterCategory | null = null

  public initStore(categories: Categories): void {
    if (this.hasSavedState()) {
      this.restoreState()
    } else {
      this._categories = sortByName(toTroubleShooterCategories(categories))
    }
  }

  public get categories(): TroubleshooterCategory[] {
    return this._categories
  }

  public get allGraphs() {
    return this._categories.flatMap((category) => {
      return category.graphs
    })
  }

  public get allGraphsMap(): Map<string, Graph> {
    return new Map(this.allGraphs.map((graph) => [graph.value, graph]))
  }

  public get selectedGraphsMap(): Map<string, Graph> {
    return new Map(this.selectedGraphs.map((graph) => [graph.value, graph]))
  }

  public selectGraphs(graphs: Graph[]) {
    graphs.forEach((graph) => (graph.isSelected = true))
    this.syncStateToSessionStorage()
  }

  public deSelectGraphs(graphs: Graph[]) {
    graphs.forEach((graph) => (graph.isSelected = false))
    this.syncStateToSessionStorage()
  }

  private syncStateToSessionStorage() {
    const state: TroubleShooterState = {
      categories: this._categories,
      startDate: this._startDate,
      endDate: this._endDate,
    }

    sessionStorage[SESSION_STORAGE_KEY] = JSON.stringify(state)
  }

  private hasSavedState(): boolean {
    return sessionStorage[SESSION_STORAGE_KEY] !== undefined
  }

  private restoreState() {
    const savedState = sessionStorage[SESSION_STORAGE_KEY]
    const state: TroubleShooterState = JSON.parse(savedState)

    this._categories = state.categories
    this._startDate = dayjs(state.startDate)
    this._endDate = dayjs(state.endDate)
  }

  public selectGraphKeys(graphKeys: string[]) {
    graphKeys.forEach((graphKey) => {
      const graph = this.allGraphsMap.get(graphKey)
      if (graph) {
        this.selectGraphs([graph])
      }
    })
  }

  public clearSelection() {
    this.deSelectGraphs(this.selectedGraphs)
  }

  public deSelectGraphKeys(graphKeys: string[]) {
    graphKeys.forEach((graphKey) => {
      const graph = this.allGraphsMap.get(graphKey)
      if (graph) {
        this.deSelectGraphs([graph])
      }
    })
  }

  public canSelectGroup(graphKeys: string[]): SelectionGrant {
    const graphs = []
    for (const key of graphKeys) {
      const graph = this.allGraphsMap.get(key)
      if (graph) {
        graphs.push(graph)
      }
    }

    if (graphs.every((graph) => graph.isSelected)) {
      return {
        allow: true,
        reason: '',
      }
    } else {
      return this.canSelect(graphs)
    }
  }

  public canSelect(graphs: Graph[]): SelectionGrant {
    // Graphs that are not toggled, can always be selected -> we only process toggled ones
    const toggled = graphs.filter((graph) => graph.isToggled)

    if (!this.allAllowed(toggled)) {
      return {
        allow: false,
        reason: 'No permissions to view this graph',
      }
    } else if (this.weightOverflow(toggled)) {
      return {
        allow: false,
        reason: 'Too high weight. Try to remove other filters first',
      }
    } else {
      return {
        allow: true,
        reason: '',
      }
    }
  }

  private allAllowed(graphs: Graph[]): boolean {
    return graphs.every((graph) => graph.allowed)
  }

  private weightOverflow(graphs: Graph[]): boolean {
    const weightSum = graphs.map((graph) => graph.weight * this.timeWeightMultiplier).reduce((a, b) => a + b, 0)
    const newTotalWeight = this.currentTotalWeight + weightSum
    return newTotalWeight >= graphMaxTotalWeight
  }

  public get selectedGraphs(): Graph[] {
    return this.allGraphs.filter((graph) => graph.isSelected)
  }

  public get selectedGraphKeys(): string[] {
    return this.selectedGraphs.filter((graph) => graph.isSelected).map((graph) => graph.value)
  }

  public get selectedAndToggledGraphs(): Graph[] {
    return this.allGraphs.filter((graph) => graph.isSelected && graph.isToggled)
  }

  public get selectedCategory(): TroubleshooterCategory | null {
    return this._selectedCategory
  }

  public setSelectedCategory(category: TroubleshooterCategory | null): void {
    this._selectedCategory = category
  }

  public get startDate(): dayjs.Dayjs {
    return this._startDate
  }

  public setStartDate(startDate: Dayjs): void {
    this._startDate = startDate
    this.syncStateToSessionStorage()
  }

  public setEndDate(endDate: Dayjs): void {
    this._endDate = endDate
    this.syncStateToSessionStorage()
  }

  public get endDate(): dayjs.Dayjs {
    return this._endDate
  }

  public get weightPercentage(): number {
    return Math.ceil((this.currentTotalWeight / graphMaxTotalWeight) * 100)
  }

  private get currentTotalWeight(): number {
    const selectedAndToggledGraphs = this.selectedAndToggledGraphs
    const filters = selectedAndToggledGraphs.map((graph) => graph.value)
    const weights = getFilterWeights(selectedAndToggledGraphs)
    return calculateTotalWeight(filters, weights, this.timeWeightMultiplier)
  }

  private get timeWeightMultiplier() {
    const { weightTimeMultiplier } = calculateTimeWeightMultiplier(
      new Date(this._startDate.toDate()),
      new Date(this._endDate.toDate()),
      graphSingleDayWeight,
    )
    return weightTimeMultiplier
  }

  public get allowedDateRanges(): { earliestStartDate: string; latestEndDate: string } {
    const selectedAndToggledGraphs = this.selectedAndToggledGraphs
    const filters = selectedAndToggledGraphs.map((graph) => graph.value)
    const weights = getFilterWeights(selectedAndToggledGraphs)

    if (this.currentTotalWeight > 0 && this._startDate && this._endDate) {
      const { earliestStartDateString, latestEndDateString } = calculateWeightDateLimits(
        calculateTotalWeight(filters, weights, graphSingleDayWeight),
        graphMaxTotalWeight,
        new Date(this._startDate.toDate()),
        new Date(this._endDate.toDate()),
      )
      return {
        earliestStartDate: earliestStartDateString,
        latestEndDate: latestEndDateString,
      }
    } else {
      return {
        earliestStartDate: graphDefaultEarliestStartDate,
        latestEndDate: graphDefaultLatestEndDate,
      }
    }
  }
}
