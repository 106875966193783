<template>
  <v-dialog width="500" :model-value="true" @after-leave="closeDialog()">
    <v-card>
      <v-card-title>Chatbot session token</v-card-title>

      <v-card-text>
        Get chatbot session token for the member
        <v-spacer />
        <strong>{{ user.email ? user.email : user.uuid }}</strong>
      </v-card-text>

      <v-row class="ma-3">
        <v-col cols="9">
          <v-text-field
            label="Session token"
            readonly
            style="font-size: 14px"
            class="col-2"
            :error-messages="errors"
            :model-value="chatbotSessionToken || ' '"
            hide-details="auto"
          >
            <template #append-inner>
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon
                    v-bind="props"
                    color="grey"
                    icon="mdi-content-copy"
                    @click="useClipboard.copy(chatbotSessionToken || '')"
                  />
                </template>
                {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="2">
          <v-btn
            small
            color="primary"
            variant="elevated"
            class="col-2 mt-3 ml-3 success"
            :disabled="loadingKey || chatbotSessionToken !== null"
            @click="getSessionKey()"
          >
            <span v-if="!loadingKey">GET</span>
            <v-progress-circular v-else indeterminate color="green" :size="20" />
          </v-btn>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="closeDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UserStore } from '#stores'

  @Component({})
  class FetchChatbotSessionToken extends Vue {
    @Prop() public user!: any

    public loadingKey = false

    public errors: string[] = []

    public readonly useClipboard = useClipboard()

    protected readonly userStore = new UserStore()

    @Emit('close')
    public closeDialog() {
      return
    }

    public get chatbotSessionToken() {
      return this.userStore.chatbotSessionToken
    }

    public mounted() {
      this.errors = []

      this.userStore.chatbotSessionToken = null
    }

    public async getSessionKey() {
      if (this.user) {
        this.errors = []

        this.loadingKey = true

        await this.userStore.getChatbotSessionToken({ uuid: this.user.uuid })

        this.loadingKey = false

        if (!this.chatbotSessionToken) {
          this.errors = ["Couldn't generate the chatbot session token. Please try again later."]
        }
      }
    }
  }

  export default toNative(FetchChatbotSessionToken)
</script>
