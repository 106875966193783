<template>
  <GraphSelectionContainer v-if="filtersVisible" @close="toggleFilters()" />

  <v-card v-else>
    <v-card-text>
      <v-alert v-if="!sensitiveDataVisible" type="info" class="mb-10" style="position: relative">
        Some filters are disabled, you can enable them by enabling showing of sensitive data from the top bar.
      </v-alert>
      <v-alert v-if="!ring" type="info" class="mb-10" style="position: relative">
        Some filters are disabled since the member does not have a ring.
      </v-alert>
      <!-- Filter button, combobox, date picker, reset buttons -->
      <v-toolbar color="transparent" height="80">
        <v-row class="px-3 flex-sm-column flex-md-row">
          <v-col class="d-flex align-start mt-3" cols="2" md="1">
            <v-btn color="rgb(var(--v-theme-surface))" class="mr-8" variant="elevated" @click="toggleFilters()">
              <v-icon left>mdi-filter-outline</v-icon>
              Settings
            </v-btn>
          </v-col>
          <v-col cols="6">
            <GraphSelectionComboBox />
          </v-col>
          <v-col cols="5">
            <DateRangePicker
              class="mt-3"
              :start-date="startDate"
              :end-date="endDate"
              :min-date="allowedDateRanges.earliestStartDate"
              :max-date="allowedDateRanges.latestEndDate"
              @update:start-date="setStartDate($event)"
              @update:end-date="setEndDate($event)"
            />
          </v-col>
        </v-row>
      </v-toolbar>
      <v-row class="px-3">
        <CustomGroupsList :horizontal="true" scope="global" :show-icons="false" :draggable="false" />
        <v-divider vertical class="mx-1 my-2" thickness="2" />
        <CustomGroupsList :horizontal="true" scope="private" :show-icons="false" :draggable="true" />
      </v-row>
      <v-row class="px-3">
        <GraphWeightBar :weight-percentage="troubleshooterStore.weightPercentage" />
      </v-row>
      <v-row class="px-3">
        <GraphToggler />
      </v-row>
      <v-row class="px-3">
        <v-col cols="12">
          <NotificationBar :notifications="graphNotifications" />
        </v-col>
      </v-row>
    </v-card-text>
    <div class="d-flex justify-space-between pb-8">
      <div class="d-flex flex-column justify-center">
        <v-btn icon @click="adjustDays(-1)">
          <v-icon icon="mdi-chevron-left" />
          <v-tooltip text="Days" activator="parent" />
        </v-btn>
        <v-btn icon @click="adjustDays(-7)">
          <v-icon icon="mdi-chevron-left" />
          <v-icon icon="mdi-chevron-left" class="ml-n3" />
          <v-tooltip text="Weeks" activator="parent" />
        </v-btn>
        <v-btn icon @click="adjustDays(-30)">
          <v-icon icon="mdi-chevron-left" />
          <v-icon icon="mdi-chevron-left" class="ml-n3" />
          <v-icon icon="mdi-chevron-left" class="ml-n3" />
          <v-tooltip text="Months" activator="parent" />
        </v-btn>
      </div>
      <div class="w-100">
        <GenericGraph
          :no-padding="true"
          :user-uuid="uuid"
          :ring="ring"
          :graphs="selectedAndToggledGraphValues"
          :start-date="startDate"
          :end-date="endDate"
        />
      </div>
      <div class="d-flex flex-column justify-center">
        <v-btn icon @click="adjustDays(1)">
          <v-icon icon="mdi-chevron-right" />
          <v-tooltip text="Days" activator="parent" />
        </v-btn>

        <v-btn icon @click="adjustDays(7)">
          <v-icon icon="mdi-chevron-right" />
          <v-icon icon="mdi-chevron-right" class="ml-n3" />
          <v-tooltip text="Weeks" activator="parent" />
        </v-btn>

        <v-btn icon @click="adjustDays(30)">
          <v-icon icon="mdi-chevron-right" />
          <v-icon icon="mdi-chevron-right" class="ml-n3" />
          <v-icon icon="mdi-chevron-right" class="ml-n3" />
          <v-tooltip text="Months" activator="parent" />
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script lang="ts">
  import dayjs from 'dayjs'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { GenericGraphStore, TroubleshooterStore, UserStore } from '#stores'

  import { Notifications } from '#types'

  @Component({})
  class Troubleshooter extends Vue {
    @Prop() public title!: string
    @Prop() public uuid!: string
    @Prop() public ring: string | null = null

    public filtersVisible = false

    public userStore = new UserStore()
    public genericGraphStore = new GenericGraphStore()
    public troubleshooterStore = new TroubleshooterStore()

    public get sensitiveDataVisible() {
      return this.userStore.sensitiveDataVisible
    }

    public toggleFilters() {
      this.filtersVisible = !this.filtersVisible
    }

    public get selectedAndToggledGraphValues(): string[] {
      return this.troubleshooterStore.selectedAndToggledGraphs.map((graph) => graph.value)
    }

    public get startDate(): string {
      return this.troubleshooterStore.startDate.format('YYYY-MM-DD')
    }

    public get endDate(): string {
      return this.troubleshooterStore.endDate.format('YYYY-MM-DD')
    }

    public adjustDays(days: number) {
      const startDate = this.troubleshooterStore.startDate
      const endDate = this.troubleshooterStore.endDate

      this.troubleshooterStore.setStartDate(startDate.add(days, 'days'))
      this.troubleshooterStore.setEndDate(endDate.add(days, 'days'))
    }

    public setStartDate(date: string) {
      this.troubleshooterStore.setStartDate(dayjs(date))
    }

    public setEndDate(date: string) {
      this.troubleshooterStore.setEndDate(dayjs(date))
    }

    public get allowedDateRanges() {
      return this.troubleshooterStore.allowedDateRanges
    }

    public get graphNotifications(): Notifications {
      return this.genericGraphStore.notifications
    }

    public mounted() {
      this.genericGraphStore.getGraphOptions({ uuid: this.uuid }).then(() => {
        const categories = this.genericGraphStore.graphOptions?.categories
        if (categories) {
          this.troubleshooterStore.initStore(categories)
        }
      })
    }
  }

  export default toNative(Troubleshooter)
</script>

<style scoped lang="scss"></style>
