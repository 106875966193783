<template>
  <v-dialog v-model="showDialog" max-width="600">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <slot />
      </v-card-text>
      <v-card-actions class="px-4">
        <v-btn variant="text" @click="close()">Close</v-btn>
        <v-btn color="primary" variant="text" data-cy="confirm-button" :disabled="disableConfirm" @click="confirm()">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Model, Prop, Vue, toNative } from 'vue-facing-decorator'

  @Component({})
  class ConfirmationDialog extends Vue {
    @Prop() public title: string = 'Confirmation'
    @Prop() public closeCallback: () => void = () => {}
    @Prop() public disableConfirm: boolean = false
    @Model() public showDialog: boolean = false

    @Emit('confirm')
    public confirm() {}

    public close() {
      this.showDialog = false
      this.closeCallback()
    }
  }
  export default toNative(ConfirmationDialog)
</script>

<style scoped lang="scss"></style>
