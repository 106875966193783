import { genericGraphChipColor } from '#views/members/constants'

import { camelCaseToWords } from '#utils/utils'

import { Categories, GraphFilterWeights } from '#types'
import { Graph, TroubleshooterCategory } from '#types/troubleshooter'

export function toTroubleShooterCategories(categories: Categories): TroubleshooterCategory[] {
  const categoriesTBS: TroubleshooterCategory[] = []
  for (const key in categories) {
    const graphs: Graph[] = []
    for (const itemKey in categories[key]) {
      if (categories[key][itemKey].show_in_troubleshooter) {
        graphs.push({
          name: categories[key][itemKey].name,
          description: categories[key][itemKey].description,
          usageInfo: categories[key][itemKey].usage_info,
          graphType: categories[key][itemKey].graph_type,
          allowed: categories[key][itemKey].allowed,
          value: itemKey,
          color: genericGraphChipColor,
          weight: categories[key][itemKey].weight,
          cacheWindow: categories[key][itemKey].cacheWindow,
          needsRingSerial: categories[key][itemKey].needsRingSerial,
          isSelected: false,
          isToggled: true,
        })
      }
    }
    categoriesTBS.push({
      name: camelCaseToWords(key),
      graphs: graphs,
    })
  }
  return categoriesTBS
}

export function sortByName(categories: TroubleshooterCategory[]): TroubleshooterCategory[] {
  categories.forEach((category) => category.graphs.sort((a, b) => a.name.localeCompare(b.name)))
  return categories
}

export function getFilterWeights(graphs: Graph[]): GraphFilterWeights {
  const graphFilterWeights: GraphFilterWeights = {}
  graphs.forEach((graph) => {
    graphFilterWeights[graph.value] = graph.weight
  })
  return graphFilterWeights
}
