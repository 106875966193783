<template>
  <div v-if="!arrythmiaToolPermission">
    <v-alert colored-border type="info" class="mb-8">
      <p>
        You do not have access rights to view sensitive health data or sensitive data is hidden, you can toggle
        sensitive data on from the toolbar if you have the rights.
      </p>
    </v-alert>
  </div>
  <div v-else>
    <v-card :loading="dataWait">
      <v-card-title>
        <div class="text-overline">Arrhythmia tool</div>
      </v-card-title>

      <v-card-text>
        <v-row v-if="disabled" class="mb-6">
          <div style="width: 100%">
            <v-sheet>
              <v-alert type="warning">You need a consent from the member to find sleep timings</v-alert>
            </v-sheet>
          </div>
        </v-row>
        <v-toolbar class="mb-8">
          <v-row align="center">
            <v-col cols="9">
              <DateRangePicker
                v-model:start-date="startDate"
                v-model:end-date="endDate"
                @valid="datesAreValid = $event"
              />
            </v-col>
            <v-col cols="3" class="text-right">
              <v-btn color="primary" variant="elevated" :disabled="searchDisabled" @click="searchSleepPeriods()">
                Find Sleep Timings
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-row v-if="sleepPeriodsRequestError">
          <div style="width: 100%">
            <v-sheet>
              <v-alert type="error">{{ sleepPeriodsRequestError }}</v-alert>
            </v-sheet>
          </div>
        </v-row>
        <v-row class="mb-6">
          <v-data-table-virtual
            :height="sleepPeriodsItems.length ? '300' : undefined"
            :headers="headers"
            :items="sleepPeriodsItems"
            :no-data-text="sleepPeriodsDataWait ? 'Loading data...' : 'No sleep data available'"
            hover
          >
            <template #item="{ item, index }">
              <tr :class="{ 'active-row': selectedSleepIndex === index }" @click="selectedSleepIndex = index">
                <td v-for="header in headers" :key="header.key">{{ item[header.key] }}</td>
              </tr>
            </template>
          </v-data-table-virtual>
        </v-row>
        <v-row v-if="!!sleepPeriodsItems.length">
          <v-col>Total sleep periods: {{ sleepPeriodsItems.length }}</v-col>
          <v-col align="end">
            <v-btn
              variant="elevated"
              color="primary"
              class="mr-4"
              rounded="xl"
              :disabled="analyzeButtonDisabled"
              @click="runArrhythmiaTool()"
            >
              Analyze
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <div v-if="ibiPlotRequestError" style="width: 100%">
            <v-sheet>
              <v-alert type="error">IBI plot: {{ ibiPlotRequestError }}</v-alert>
            </v-sheet>
          </div>
          <div v-if="ppgPlotRequestError" style="width: 100%">
            <v-sheet>
              <v-alert type="error">PPG plot: {{ ppgPlotRequestError }}</v-alert>
            </v-sheet>
          </div>
        </v-row>
        <v-row>
          <v-col cols="12">
            <PlotlyView :data="ibiPlotData" :full-screen-supported="true" title="IBI Data" />
          </v-col>
        </v-row>

        <v-carousel v-model="selectedPPGGraphIndex" hide-delimiters height="650" class="mb-6">
          <template #prev="{ props }">
            <v-icon size="25" class="mr-3" @click="props.onClick">mdi-chevron-left</v-icon>
          </template>
          <template #next="{ props }">
            <v-icon size="25" class="ml-3" @click="props.onClick">mdi-chevron-right</v-icon>
          </template>

          <p class="text-overline ml-8">PPG Data ({{ selectedPPGGraphIndex + 1 }}/{{ ppgPlotData.length }})</p>
          <v-sheet :min-height="600" tile>
            <v-carousel-item
              v-for="(graph, index) in ppgPlotData"
              :key="index"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <PlotlyView :data="selectedPPGGraphIndex == index ? graph : emptyGraph" :full-screen-supported="true" />
            </v-carousel-item>
          </v-sheet>
        </v-carousel>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { sleepPeriodsHeaders } from '#views/members/constants'

  import { extractSleepPeriodsFromResponse } from '#utils/arrhythmia/extract'
  import { EMPTY_GRAPH } from '#utils/generic-graph/generic-graph'

  import { AppStore, ArrhythmiaStore, PrefsStore, UserStore } from '#stores'

  import { DisplayTimezone, Nullable, SleepPeriodsItem, SleepPeriodsResponse } from '#types'

  @Component
  class ArrhythmiaTool extends mixins(DateTime) {
    @Prop() public user!: any

    @Prop() public disabled!: boolean

    public startDate = ''
    public endDate = ''
    public expanded = false
    public datesAreValid = true
    public sleepPeriodsItems: SleepPeriodsItem[] = []
    public selectedSleepIndex: number | null = null

    public appStore = new AppStore()
    public userStore = new UserStore()
    public arrhythmiaStore = new ArrhythmiaStore()
    public prefsStore = new PrefsStore()

    public selectedPPGGraphIndex = 0

    public emptyGraph = EMPTY_GRAPH

    public get headers() {
      return sleepPeriodsHeaders
    }

    public get rights() {
      return this.appStore.activeRights
    }

    public get access() {
      return this.userStore.user?.ownerAccess || this.userStore.user?.sharedAccess || this.userStore.user?.supportAccess
    }

    public get dataWait() {
      return this.arrhythmiaStore.dataWait
    }

    public get sleepPeriodsResponse(): Nullable<SleepPeriodsResponse> {
      return this.arrhythmiaStore.sleepPeriods
    }

    public get ibiPlotData() {
      return this.arrhythmiaStore.ibiPlotData ? this.arrhythmiaStore.ibiPlotData : EMPTY_GRAPH
    }

    public get ppgPlotData() {
      return this.arrhythmiaStore.ppgPlotData?.length ? this.arrhythmiaStore.ppgPlotData : [EMPTY_GRAPH]
    }

    public get sensitiveDataVisible() {
      return this.userStore.sensitiveDataVisible
    }

    public get arrythmiaToolPermission() {
      return !!(this.rights.includes('allowSensitiveDataAccess') || this.access) && this.sensitiveDataVisible
    }

    public get displayTimezone() {
      return this.prefsStore.displayTimezone
    }

    public get sleepPeriodsRequestError() {
      return this.arrhythmiaStore.sleepPeriodsRequestError
    }

    public get ibiPlotRequestError() {
      return this.arrhythmiaStore.ibiPlotDataRequestError
    }

    public get ppgPlotRequestError() {
      return this.arrhythmiaStore.ppgPlotDataRequestError
    }

    public get sleepPeriodsDataWait(): boolean {
      return this.arrhythmiaStore.waitingForSleepPeriods()
    }

    @Watch('sleepPeriodsResponse')
    protected onSleepPeriodsResponseChanged(sleepPeriodsResponse: Nullable<SleepPeriodsResponse>) {
      if (sleepPeriodsResponse) {
        this.sleepPeriodsItems = extractSleepPeriodsFromResponse(sleepPeriodsResponse, this.prefsStore.displayTimezone)
      } else {
        this.sleepPeriodsItems = []
      }
    }

    @Watch('displayTimezone')
    protected onTimezoneSettingChanged(displayTimezone: DisplayTimezone) {
      if (this.sleepPeriodsResponse) {
        this.sleepPeriodsItems = extractSleepPeriodsFromResponse(this.sleepPeriodsResponse, displayTimezone)
      }
    }

    public mounted() {
      this.startDate = this.$dayjs().subtract(2, 'week').format('YYYY-MM-DD')
      this.endDate = this.$dayjs().format('YYYY-MM-DD')

      this.arrhythmiaStore.resetData()
    }

    public async searchSleepPeriods() {
      this.selectedSleepIndex = null
      await this.arrhythmiaStore.getSleepPeriods(this.user.uuid, this.startDate, this.endDate)
    }

    public async runArrhythmiaTool() {
      // selectedSleepIndex is a index number and could be 0, so we can't use a simple true or false check.
      if (this.selectedSleepIndex != null && typeof this.selectedSleepIndex === 'number') {
        const sleepPeriod: SleepPeriodsItem = this.sleepPeriodsItems[this.selectedSleepIndex]
        const { bedtimeStartFull, bedtimeEndFull } = sleepPeriod
        if (bedtimeStartFull && bedtimeEndFull) {
          this.selectedPPGGraphIndex = 0
          await this.arrhythmiaStore.getIbiPlotData(this.user.uuid, bedtimeStartFull, bedtimeEndFull)
          await this.arrhythmiaStore.getPPGPlotData(this.user.uuid, bedtimeStartFull, bedtimeEndFull)
        }
      }
    }

    public get searchDisabled() {
      return !this.startDate || !this.endDate || !this.datesAreValid || this.dataWait || !!this.disabled
    }

    public get analyzeButtonDisabled() {
      return this.selectedSleepIndex === null || !!this.disabled
    }
  }

  export default toNative(ArrhythmiaTool)
</script>
<style scoped>
  .active-row {
    background-color: #ffdbe2; /* Highlight color for active row */
  }
</style>
