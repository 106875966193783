<template>
  <v-app>
    <template v-if="maintenance">
      <v-container class="fill-height" :fluid="true">
        <v-row align="center" justify="center">
          <v-col class="text-center">
            <v-img style="margin: -48px auto" width="600" src="/logos/oura-black.png" />

            <div class="text-h5 text-primary">Darwin - Users journal</div>

            <div class="pa-8">Darwin is currently under maintenance, come back later!</div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else-if="auth === undefined">
      <OuraSpinner />
    </template>
    <template v-else-if="auth === null">
      <OuraAuth
        title="Darwin - Users journal"
        data-cy="oura-auth-component"
        @login="appStore.login(!!$route.meta?.panel)"
      />

      <v-dialog v-model="update" width="600">
        <v-card>
          <v-card-title>Update in progress</v-card-title>

          <v-card-text>
            We are currently updating the system, this should normally take only 5-15 minutes. If this dialog does not
            go away in ~30 minutes please report this in #darwin Slack channel.
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <template v-else-if="auth !== undefined">
      <v-navigation-drawer
        v-if="!$route.meta || !$route.meta.panel"
        v-model="navDrawer"
        :rail="$vuetify.display.smAndDown ? false : miniNav"
        :clipped="$vuetify.display.smAndDown"
        :permanent="$vuetify.display.mdAndUp"
        :temporary="$vuetify.display.smAndDown"
      >
        <div class="d-flex flex-column fill-height">
          <v-list comfort nav :dense="$vuetify.display.smAndDown">
            <v-list-item
              link
              prepend-icon="mdi-menu"
              class="mb-0"
              @click="$vuetify.display.smAndDown ? (navDrawer = !navDrawer) : (miniNav = !miniNav)"
            />
            <v-divider />
            <v-list-item prepend-icon="mdi-home" link :to="'/'" class="mb-0">
              <v-list-item-title>Home</v-list-item-title>
              <v-tooltip right activator="parent" :disabled="!miniNav">Home</v-tooltip>
            </v-list-item>

            <v-divider />

            <v-list-item
              prepend-icon="mdi-account-search"
              link
              data-cy="left-menu-users-list"
              :to="'/users'"
              class="mb-0"
              :active="$route.path.startsWith('/users')"
            >
              <v-list-item-title>Oura Members</v-list-item-title>
              <v-tooltip right activator="parent" :disabled="!miniNav">Oura Members</v-tooltip>
            </v-list-item>

            <v-list-item
              prepend-icon="mdi-database-search"
              link
              :to="'/rings'"
              class="mb-0"
              :active="$route.path.startsWith('/rings')"
            >
              <v-list-item-title>Oura Devices</v-list-item-title>
              <v-tooltip right activator="parent" :disabled="!miniNav">Oura Devices</v-tooltip>
            </v-list-item>
            <v-list-item
              prepend-icon="mdi-account-cash"
              link
              :to="'/orders'"
              class="mb-0"
              :active="$route.path.startsWith('/orders')"
            >
              <v-list-item-title>Oura Orders</v-list-item-title>
              <v-tooltip right activator="parent" :disabled="!miniNav">Oura Orders</v-tooltip>
            </v-list-item>
            <v-list-item
              v-if="rights.includes('rolesOuraAppsAdmin')"
              prepend-icon="mdi-apple-icloud"
              link
              :to="'/apps'"
              class="mb-0"
              :active="$route.path.startsWith('/apps')"
            >
              <v-list-item-title>Oura Apps</v-list-item-title>
              <v-tooltip right activator="parent" :disabled="!miniNav">Oura Apps</v-tooltip>
            </v-list-item>
            <v-list-item
              v-if="rights.includes('rolesOuraTeamsAdmin') || rights.includes('rolesOuraTeamsViewer')"
              prepend-icon="mdi-account-multiple"
              link
              :to="'/teams'"
              class="mb-0"
              :active="$route.path.startsWith('/teams')"
            >
              <v-list-item-title>Oura Teams</v-list-item-title>
              <v-tooltip right activator="parent" :disabled="!miniNav">Oura Teams</v-tooltip>
            </v-list-item>

            <v-divider />
            <v-list-item
              v-if="rights.includes('rolesBulkAccountsAdmin') || rights.includes('rolesOuraUsersAdmin')"
              prepend-icon="mdi-account-cog"
              link
              :to="'/admin/bulk'"
              class="mb-0"
              :active="$route.path.startsWith('/admin')"
            >
              <v-list-item-title>Accounts</v-list-item-title>
              <v-tooltip right activator="parent" :disabled="!miniNav">Accounts</v-tooltip>
            </v-list-item>
          </v-list>
          <v-spacer />
          <v-list nav>
            <v-list-item link prepend-icon="mdi-bug" class="mb-0" @click="$showJiraIssueCollectorDialog()">
              <v-list-item-title>Feedback</v-list-item-title>

              <v-tooltip right activator="parent" :disabled="!miniNav">Feedback</v-tooltip>
            </v-list-item>
            <v-list-item link prepend-icon="mdi-logout" to="/logout" class="mb-0" data-cy="logout-button">
              <v-list-item-title>Logout</v-list-item-title>

              <v-tooltip right activator="parent" :disabled="!miniNav">Logout</v-tooltip>
            </v-list-item>
          </v-list>
        </div>
      </v-navigation-drawer>

      <v-app-bar
        v-if="!$route.meta || !$route.meta.panel"
        :clipped-left="$vuetify.display.smAndDown"
        app
        color="black"
        fixed
        clipped-right
        :class="'env-' + cloudEnv"
      >
        <template #prepend>
          <v-app-bar-nav-icon v-if="$vuetify.display.smAndDown" @click="navDrawer = !navDrawer" />

          <v-img
            alt="Vuetify Logo"
            class="mx-4"
            contain
            src="/logos/logo-white.png"
            transition="scale-transition"
            width="24"
          />
        </template>

        <v-app-bar-title>
          <div class="d-flex flex-row flex-no-wrap align-center text-no-wrap mt-1">
            <span class="text-h5 text-white flex-shrink-0 mr-2">Darwin -</span>

            <v-menu offset="16" location="bottom end">
              <template #activator="{ props }">
                <div v-bind="props" class="d-flex flex-row flex-shrink-0 mt-1" style="cursor: pointer">
                  <span class="text-truncate" style="min-width: 160px">
                    {{ cloudEnvs[cloudEnv] || 'Users journal' }}
                  </span>

                  <v-icon class="mx-2">mdi-chevron-down</v-icon>
                </div>
              </template>

              <v-list>
                <v-list-item title="Sandbox environment" @click="cloudEnv = 'test'">
                  <template #prepend>
                    <v-icon color="green">mdi-circle</v-icon>
                  </template>
                  <template #subtitle>
                    <div>Apps: Debug, Feature branches</div>
                  </template>
                </v-list-item>
                <v-list-item title="Staging environment" @click="cloudEnv = 'stage'">
                  <template #prepend>
                    <v-icon color="purple">mdi-circle</v-icon>
                  </template>
                  <template #subtitle>
                    <div>Apps: Staging, Experimental</div>
                  </template>
                </v-list-item>
                <v-list-item title="Release environment" @click="cloudEnv = 'prod'">
                  <template #prepend>
                    <v-icon color="black">mdi-circle</v-icon>
                  </template>
                  <template #subtitle>
                    <div>Apps: Production, Research</div>
                  </template>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-app-bar-title>

        <MembersSearchBar
          v-model:search-box-notification="searchBoxNotification"
          :title="title"
          :rights="rights"
          :users="users"
          :user="user"
        />

        <v-spacer v-if="!$route.params.uuid && (!$route.meta || $route.meta.title !== 'Home')" />

        <v-btn icon class="mr-2" @click.stop="settings = !settings">
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-icon dark v-bind="props">mdi-cog</v-icon>
            </template>
            Quick settings
          </v-tooltip>
        </v-btn>

        <v-menu v-if="auth && auth.email" offset-y>
          <template #activator="{ props }">
            <div data-cy="app-menu-email" class="d-flex text-white py-4" v-bind="props">
              <span class="text-subtitle-1">{{ auth.email }}</span>
              <v-icon color="white">mdi-chevron-down</v-icon>
            </div>
          </template>
          <v-list>
            <v-list-item @click="toggleRights('rolesOuraAppsAdmin')">
              <v-list-item-title>Oura apps admin</v-list-item-title>
              <template #append>
                <div :class="getRightColor('rolesOuraAppsAdmin')">
                  {{ rights.includes('rolesOuraAppsAdmin') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">Oura Apps page</v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="toggleRights('rolesOuraTeamsAdmin')">
              <v-list-item-title>Oura teams admin</v-list-item-title>
              <template #append>
                <div :class="getRightColor('rolesOuraTeamsAdmin')">
                  {{ rights.includes('rolesOuraTeamsAdmin') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">Oura Teams page & features</v-list-item-subtitle>
            </v-list-item>

            <v-list-item @click="toggleRights('rolesOuraTeamsViewer')">
              <v-list-item-title>Oura teams viewer</v-list-item-title>
              <template #append>
                <div :class="getRightColor('rolesOuraTeamsViewer')">
                  {{ rights.includes('rolesOuraTeamsViewer') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">
                Oura Teams page permission to see list of Oura Teams
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item data-cy="app-menu-oura-user-admin-rights" @click="toggleRights('rolesOuraUsersAdmin')">
              <v-list-item-title>Oura users admin</v-list-item-title>
              <template #append>
                <div :class="getRightColor('rolesOuraUsersAdmin')">
                  {{ rights.includes('rolesOuraUsersAdmin') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">Full/partial users searching</v-list-item-subtitle>
              <v-list-item-subtitle v-if="active">Access to Auditing page</v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="toggleRights('rolesBulkAccountsAdmin')">
              <v-list-item-title>Bulk accounts admin</v-list-item-title>
              <template #append>
                <div :class="getRightColor('rolesBulkAccountsAdmin')">
                  {{ rights.includes('rolesBulkAccountsAdmin') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">Access to bulk accounts batch running</v-list-item-subtitle>
            </v-list-item>

            <v-list-item @click="toggleRights('rolesMembershipAdmin')">
              <v-list-item-title>Membership admin</v-list-item-title>
              <template #append>
                <div :class="getRightColor('rolesMembershipAdmin')">
                  {{ rights.includes('rolesMembershipAdmin') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">Reset all user’s subscriptions</v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="toggleRights('rolesPaymentAdmin')">
              <v-list-item-title>Payment data admin</v-list-item-title>
              <template #append>
                <div :class="getRightColor('rolesPaymentAdmin')">
                  {{ rights.includes('rolesPaymentAdmin') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">
                Edit payment related sensitive data (invoices for subscriptions)
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="toggleRights('rolesPaymentViewer')">
              <v-list-item-title>Payment data viewer</v-list-item-title>
              <template #append>
                <div :class="getRightColor('rolesPaymentViewer')">
                  {{ rights.includes('rolesPaymentViewer') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">
                View payment related sensitive data (invoices for subscriptions)
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider />
            <v-list-item @click="toggleRights('allowAccountEditAccess')">
              <v-list-item-title>Account edit rights</v-list-item-title>
              <template #append>
                <div :class="getRightColor('allowAccountEditAccess')">
                  {{ rights.includes('allowAccountEditAccess') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">
                Allows editing subscriptions (cancel, extend trial, etc.)
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="toggleRights('allowRingHistoryAccess')">
              <v-list-item-title>Ring history rights</v-list-item-title>
              <template #append>
                <div :class="getRightColor('allowRingHistoryAccess')">
                  {{ rights.includes('allowRingHistoryAccess') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">
                Allows to see account UUID’s for the accounts where ring (serial) has been in use
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item data-cy="app-menu-personal-data-rights" @click="toggleRights('allowPersonalDataAccess')">
              <v-list-item-title>
                Personal data rights
                <span v-if="active">(Level 2)</span>
              </v-list-item-title>
              <template #append>
                <div :class="getRightColor('allowPersonalDataAccess')">
                  {{ rights.includes('allowPersonalDataAccess') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">Allows seeing the member email address</v-list-item-subtitle>
              <v-list-item-subtitle v-if="active">Allows searching with email (full email match)</v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="toggleRights('allowSensitiveDataAccess')">
              <v-list-item-title>
                Sensitive data rights
                <span v-if="active">(Level 3)</span>
              </v-list-item-title>
              <template #append>
                <div :class="getRightColor('allowSensitiveDataAccess')">
                  {{ rights.includes('allowSensitiveDataAccess') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">Allows seeing health data related features</v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="toggleRights('allowDataDeletionAccess')">
              <v-list-item-title>Data deletion rights</v-list-item-title>
              <template #append>
                <div :class="getRightColor('allowDataDeletionAccess')">
                  {{ rights.includes('allowDataDeletionAccess') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">
                Allows seeing the marking of member to be deleted action
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="toggleRights('allowDataDownloadAccess')">
              <v-list-item-title>
                Data download rights
                <span v-if="active">(Level 4)</span>
              </v-list-item-title>
              <template #append>
                <div :class="getRightColor('allowDataDownloadAccess')">
                  {{ rights.includes('allowDataDownloadAccess') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">
                Allows seeing the client config change db viewer, debug logs and data files
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="active">
                Allow fetching ring authentication keys for the user’s rings
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="toggleRights('allowPartialSearchAccess')">
              <v-list-item-title>Partial search rights</v-list-item-title>
              <template #append>
                <div :class="getRightColor('allowPartialSearchAccess')">
                  {{ rights.includes('allowPartialSearchAccess') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">
                Allows search results to be 3 letters off from the exact email to be searched
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item @click="toggleRights('allowAnalyticsIdAccess')">
              <v-list-item-title>Analytics ID access</v-list-item-title>
              <template #append>
                <div :class="getRightColor('allowAnalyticsIdAccess')">
                  {{ rights.includes('allowAnalyticsIdAccess') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">Allow member analytics ID to be shown</v-list-item-subtitle>
            </v-list-item>

            <v-list-item @click="toggleRights('allowAssaBrowserAccess')">
              <v-list-item-title>ASSA viewer access</v-list-item-title>
              <template #append>
                <div :class="getRightColor('allowAssaBrowserAccess')">
                  {{ rights.includes('allowAssaBrowserAccess') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">Allow access to ASSA browser</v-list-item-subtitle>
            </v-list-item>

            <v-list-item @click="toggleRights('allowArrhythmiaToolAccess')">
              <v-list-item-title>Arrhythmia tool access</v-list-item-title>
              <template #append>
                <div :class="getRightColor('allowArrhythmiaToolAccess')">
                  {{ rights.includes('allowArrhythmiaToolAccess') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">Allow access to Arrhythmia tool</v-list-item-subtitle>
            </v-list-item>

            <v-divider />
            <v-list-item>
              <v-list-item-title>Darwin beta features</v-list-item-title>
              <template #append>
                <div :class="getGroupColor('groupDarwinBetaUsers')">
                  {{ groups.includes('groupDarwinBetaUsers') ? 'YES' : 'NO' }}
                </div>
              </template>
              <v-list-item-subtitle v-if="active">Access to beta features in Darwin</v-list-item-subtitle>
            </v-list-item>
            <!-- <v-list-item-icon>
              <v-btn @click="">
                <v-icon small>mdi-subtitles-outline</v-icon>
              </v-btn>
            </v-list-item-icon> -->
          </v-list>
        </v-menu>

        <OuraApps :env="env" />
      </v-app-bar>

      <v-navigation-drawer
        v-if="!$route.meta || !$route.meta.panel"
        v-model="settings"
        disable-resize-watcher
        width="400"
        location="right"
        style="max-width: calc(100vw - 56px)"
        :temporary="$vuetify.display.mdAndDown"
      >
        <v-toolbar title="Quick settings">
          <v-spacer />

          <v-btn icon="mdi-window-close" @click="settings = false" />
        </v-toolbar>

        <v-container>
          <div class="text-overline">Member Data</div>

          <v-sheet class="px-3">
            <v-switch
              class="reversed mt-2 mb-2"
              color="primary"
              inset
              label="Use member's timezone"
              :model-value="timezonePrefs !== 'UTC'"
              :disabled="!timezoneToggleAllowed"
              @change="toggleTimezoneSetting(true)"
            />
          </v-sheet>

          <v-divider class="mx-2" />

          <div class="text-overline">Troubleshooter/Graphs</div>

          <v-sheet class="px-3">
            <v-switch
              class="reversed mt-2 mb-2"
              color="primary"
              inset
              :label="`Rendering mode: ${graphModePrefs.toString()} `"
              :model-value="graphModePrefs.toString() !== 'SVG'"
              @change="toggleGraphMode(true)"
            >
              <template #prepend>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-icon v-bind="props" size="20">mdi-help-circle-outline</v-icon>
                  </template>
                  Change the rendering method of the graphs.
                  <br />
                  WebGL is the default and recommended option but if you're experiencing issues with it switching to SVG
                  might help.
                </v-tooltip>
              </template>
            </v-switch>
          </v-sheet>

          <v-divider class="mx-2" />

          <template v-if="groups && groups.includes('groupInternalToolingTeam')">
            <div class="text-overline">Admin settings</div>

            <v-sheet class="px-3">
              <v-switch v-model="adminDebugMode" class="reversed mt-2 mb-2" color="primary" inset label="Debug mode" />
            </v-sheet>
          </template>

          <v-divider class="mx-2" />

          <div class="text-overline">User settings</div>
          <v-sheet class="px-3">
            <v-switch
              class="reversed mt-2 mb-2"
              color="primary"
              inset
              label="Toggle rights description"
              @change="toggleDescription()"
            />
            <v-switch
              :model-value="featureFlagDisplay"
              class="reversed mt-2 mb-2"
              color="primary"
              inset
              label="Feature flags list"
              @change="toggleFeatureFlag()"
            />
            <v-switch
              :model-value="theme?.global?.current?.value?.dark"
              class="reversed mt-2 mb-2"
              color="primary"
              inset
              label="Dark mode [BETA]"
              @change="toggleTheme()"
            />
          </v-sheet>

          <v-divider class="mx-2" />

          <DebugLogs v-if="!!rights" />

          <v-divider class="mx-2" />

          <PersonalData v-if="!!auth && !!rights" />

          <v-divider class="mx-2" />

          <DarwinFeatures v-if="featureFlagDisplay" />

          <v-divider v-if="adminDebugMode" class="mx-2" />

          <DebugTools v-if="adminDebugMode" />
        </v-container>
      </v-navigation-drawer>

      <v-main>
        <v-alert
          v-if="adminDebugMode"
          tile
          density="compact"
          color="yellow"
          style="z-index: 100; margin: 0 !important; color: white; position: sticky; width: 100%"
        >
          Debug mode
        </v-alert>
        <v-alert
          v-if="sessionNotice"
          tile
          density="compact"
          color="blue"
          style="z-index: 100; margin: 0 !important; color: white; position: sticky; width: 100%"
        >
          Session expiring in
          <strong>{{ sessionExpirationInMinutes }}</strong>
          minutes. Please refresh your session by
          <a class="text-info font-weight-bold" href="#" @click="refreshAuthSession()">logging in</a>
          again.
        </v-alert>
        <v-alert
          v-if="searchBoxNotification"
          tile
          density="compact"
          color="blue"
          style="z-index: 100; margin: 0 !important; color: white; position: sticky; width: 100%"
        >
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="searchBoxNotification" />
        </v-alert>
        <v-progress-linear v-show="dataWait" class="global" indeterminate height="8" color="pink" />
        <div>
          <router-view />
        </div>
      </v-main>

      <v-snackbar v-model="snackbar" bottom right :timeout="5000">
        {{
          inviteSent ? 'Invitation email sent' : verificationSent ? 'Verification email sent' : 'Unknown error occured'
        }}
        <template #actions>
          <v-btn color="white" variant="text" @click="snackbar = false">Close</v-btn>
        </template>
      </v-snackbar>

      <v-snackbar location="end bottom" :timeout="-1" :model-value="newConfig || serviceWorker.needRefresh.value">
        New update available

        <template #actions>
          <v-btn
            variant="text"
            @click="serviceWorker.needRefresh.value ? serviceWorker.updateServiceWorker() : updateRemoteConfig()"
          >
            Refresh
          </v-btn>
        </template>
      </v-snackbar>

      <NewFeature v-if="!$route.meta.panel" />

      <v-footer :class="[env]">
        <v-alert v-model="error" closable color="red" variant="flat" class="bottomAlert">
          Backend crash detected, data loading may have been interrupted and therefore not showing in the UI
        </v-alert>

        <v-alert v-model="connError" closable color="red" variant="flat" class="bottomAlert">
          Network connection error, data loading may have been interrupted and therefore not showing in the UI
        </v-alert>

        <v-alert v-if="unsupportedBrowser" closable variant="flat" class="bottomAlert">
          Your browser is not supported by Darwin. Please use Google Chrome or Safari browser.
        </v-alert>

        <div v-if="env !== 'release'" class="px-4 py-2 mx-n4 my-n2 mt-4 innerFooter">
          <span class="d-none d-sm-flex">Environment:</span>
          <b class="ml-2">{{ env }}</b>
          <v-spacer />
          <span class="d-none d-sm-flex">Version:</span>
          <b class="ml-2">{{ version }} ({{ revision }})</b>
        </div>
      </v-footer>
    </template>
  </v-app>
</template>

<script lang="ts">
  import { startCase } from 'lodash-es'

  import { useRegisterSW } from 'virtual:pwa-register/vue'

  import { ThemeInstance, useTheme } from 'vuetify'

  import { Component, Setup, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent, setUserId, setUserProperties } from 'firebase/analytics'
  import { fetchAndActivate } from 'firebase/remote-config'

  import { OuraApps, OuraAuth, OuraSpinner } from '@jouzen/outo-toolkit-vuetify'

  import { DateTime } from '#mixins/dateTime'

  import {
    AdminStore,
    AppStore,
    AppsStore,
    DebugStore,
    FilesStore,
    PrefsStore,
    RingsStore,
    TasksStore,
    TeamsStore,
    UserStore,
  } from '#stores'

  import { GraphMode, Member } from '#types'

  @Component({
    components: {
      OuraAuth,
      OuraApps,
      OuraSpinner,
    },
  })
  class App extends mixins(DateTime) {
    @Setup(() => useTheme())
    public readonly theme: ThemeInstance | undefined

    @Setup(() =>
      useRegisterSW({
        onRegistered(r) {
          if (r) {
            setInterval(
              () => {
                r.update()
              },
              15 * 60 * 1000,
            )
          }
        },
      }),
    )
    public readonly serviceWorker: any

    public adminStore = new AdminStore()
    public appStore = new AppStore()
    public appsStore = new AppsStore()
    public debugStore = new DebugStore()
    public filesStore = new FilesStore()
    public prefsStore = new PrefsStore()
    public ringsStore = new RingsStore()
    public tasksStore = new TasksStore()
    public teamsStore = new TeamsStore()
    public userStore = new UserStore()

    public title = 'Darwin - Users journal'
    public tooltip = ''
    public active = false
    public error = false
    public update = false
    public snackbar = false
    public settings = false
    public maintenance = false
    public miniNav = true
    public navDrawer = true
    public newConfig = false
    public connError = false
    public authError = false
    public inviteSent = false
    public verificationSent = false
    public sessionExpirationInMinutes = 100
    public sessionExpirationInterval = 0
    public featureFlagDisplay = false

    public cloudEnvs: any = {
      dev: 'Sandbox env',
      test: 'Sandbox env',
      stage: 'Staging env',
      prod: 'Release env',
    }

    public updateSW: any = undefined

    public env = import.meta.env.VITE_APP_ENV

    public version = process.env.APP_RELEASE_VERSION
    public revision = process.env.APP_RELEASE_REVISION

    //need to implemenent props
    public searchBoxNotification: string | undefined = ''

    public get auth() {
      return this.appStore.user
    }

    public get user(): Member | null {
      return this.userStore.user
    }

    public get users() {
      const users = this.userStore.searchResultUsers
      return users || []
    }

    public get claims() {
      return this.appStore.claims || {}
    }

    public get groups() {
      return this.appStore.groups || []
    }

    public get rights() {
      return this.appStore.activeRights || []
    }

    public get overrides() {
      return this.appStore.overrides || []
    }

    public get dataWait() {
      return (
        this.adminStore.dataWait ||
        this.appsStore.dataWait ||
        this.debugStore.dataWait ||
        this.filesStore.dataWait ||
        this.ringsStore.dataWait ||
        this.tasksStore.dataWait ||
        this.teamsStore.dataWait ||
        this.userStore.dataWait
      )
    }

    public get userWait() {
      return this.userStore.dataWait
    }

    public get cloudEnv() {
      return this.appStore.cloudEnv
    }

    public set cloudEnv(env: string) {
      this.appStore.setCloudEnv(env)
    }

    public get sessionNotice() {
      return this.sessionExpirationInMinutes <= 30
    }

    public get timezonePrefs() {
      return this.prefsStore.timezonePrefs
    }

    public get graphModePrefs() {
      return this.prefsStore.graphMode
    }

    public get adminDebugMode() {
      return this.prefsStore.adminDebugMode && this.groups?.includes('groupInternalToolingTeam')
    }

    public set adminDebugMode(value: boolean) {
      this.prefsStore.toggleAdminDebugMode(value)
      if (value) {
        console.log('Reloading to turn on admin debug mode')

        window.location.reload()
      }
    }

    public get timezoneToggleAllowed() {
      return this.rights.includes('allowPersonalDataAccess') || this.user?.ownerAccess || this.user?.supportAccess
    }

    public get unsupportedBrowser() {
      return !navigator.userAgent.match(/chrome|chromium|safari/i)
    }

    public get sessionExpirationTime() {
      return this.appStore.sessionExpirationTime ?? 0
    }

    public toggleDescription() {
      //could potenially save this locally
      this.active = !this.active
    }

    public toggleFeatureFlag() {
      this.featureFlagDisplay = !this.featureFlagDisplay
      window.sessionStorage.setItem('featureFlagDisplay', String(this.featureFlagDisplay))
    }

    public toggleTheme() {
      this.theme!.global.name.value = this.theme!.global.current.value.dark ? 'light' : 'dark'

      localStorage.setItem('OuraDarkMode', this.theme!.global.current.value.dark.toString())
    }

    @Watch('miniNav')
    protected onMiniNavChanged(val: any) {
      localStorage.OuraMiniNav = val
    }

    @Watch('$route', { immediate: true })
    protected onRouteChanged(val: any) {
      this.searchBoxNotification = ''
      document.title = this.title

      if (val.params?.uuid) {
        document.title = this.user?.email || this.title || 'Users journal'
      }

      if (val?.params?.uuid && val?.params?.uuid !== this.userStore.user?.uuid) {
        const page = val.params?.uuid ? '/' : val.meta.title ? '/ ' + val.meta.title : ''
        this.title = 'Darwin - Users journal ' + page
      }
    }

    @Watch('auth')
    protected async onAuthChanged(val: any) {
      if (val) {
        setUserId(this.$analytics, val.email)

        setUserProperties(this.$analytics, {
          rolesOuraAppsAdmin: this.rights.includes('rolesOuraAppsAdmin'),
          rolesOuraTeamsAdmin: this.rights.includes('rolesOuraTeamsAdmin'),
          rolesOuraTeamsViewer: this.rights.includes('rolesOuraTeamsViewer'),
          rolesOuraUsersAdmin: this.rights.includes('rolesOuraUsersAdmin'),
          groupDarwinBetaUsers: this.groups.includes('groupDarwinBetaUsers'),
          allowRingHistoryAccess: this.rights.includes('allowRingHistoryAccess'),
          allowDataDeletionAccess: this.rights.includes('allowDataDeletionAccess'),
          allowDataDownloadAccess: this.rights.includes('allowDataDownloadAccess'),
          allowPersonalDataAccess: this.rights.includes('allowPersonalDataAccess'),
          allowSensitiveDataAccess: this.rights.includes('allowSensitiveDataAccess'),
          groupDarwinDeveloper: this.groups.includes('groupInternalToolingTeam'),
          groupCXTSLVL3: this.groups.includes('groupCXTSLevel3'),
        })

        this.$updateJiraIssueCollectorFields({
          email: val?.email || '',
          fullname: startCase((val?.email || '').replace('.', ' ').replace('@ouraring.com', '')),
        })
      }
    }

    @Watch('user')
    protected onUserChanged(val: any) {
      this.$updateJiraIssueCollectorFields({
        customfield_11275: val?.uuid || '',
      })
      this.onRouteChanged(this.$route)
    }

    @Watch('sessionExpirationTime', { immediate: true })
    protected onSessionExpirationTimeChanged() {
      this.setSessionExpiresInMinutes()

      if (this.sessionExpirationInterval) {
        clearInterval(this.sessionExpirationInterval)
      }

      this.sessionExpirationInterval = window.setInterval(() => {
        this.setSessionExpiresInMinutes()
      }, 1000 * 60)
    }

    public created() {
      logEvent(this.$analytics, 'app_loaded', {
        page_location: window.location.toString().split('?')[0],
        page_title: this.title,
      })
    }

    public async mounted() {
      this.userStore.setSensitiveDataVisible(false)

      document.addEventListener('notify', this.notifyNetworkStatus)

      this.featureFlagDisplay = window.sessionStorage.getItem('featureFlagDisplay') == 'true'

      if (localStorage.OuraMiniNav) {
        this.miniNav = localStorage.OuraMiniNav === 'true'
      }

      fetchAndActivate(this.$remoteConfig)
        .then((activated: boolean) => {
          if (activated) {
            this.newConfig = true
          }
        })
        .catch((_err) => {
          console.error('Failed to fetch firebase remote config')
        })

      this.theme!.global.name.value =
        localStorage.getItem('OuraDarkMode') === 'true' && !window.location.href.includes('/sidecar') ? 'dark' : 'light'
    }

    public beforeDestroy() {
      document.removeEventListener('notify', this.notifyNetworkStatus)

      if (this.sessionExpirationInterval) {
        clearInterval(this.sessionExpirationInterval)
      }
    }

    public refreshAuthSession() {
      this.appStore.logout(true)
    }

    public updateRemoteConfig() {
      console.log('Update remote config')

      window.location.reload()
    }

    public notifyNetworkStatus(event: any) {
      this.inviteSent = event.detail?.inviteSent
      this.verificationSent = event.detail?.verificationSent

      this.error = !!event.detail.backendError
      this.update = !!event.detail.backendUpdate

      this.connError = !!event.detail.connectionError
      this.authError = !!event.detail.authError

      if (!this.error && !this.update && !this.connError && !this.authError) {
        this.snackbar = true
      }
    }

    public getGroupColor(group: string) {
      return this.groups.includes(group)
        ? 'text-success'
        : this.overrides.includes(group)
          ? 'text-info'
          : 'text-warning'
    }

    public getRightColor(right: string) {
      return this.rights.includes(right)
        ? 'text-success'
        : this.overrides.includes(right)
          ? 'text-info'
          : 'text-warning'
    }

    public toggleRights(right: string) {
      if (import.meta.env.VITE_APP_ENV !== 'release') {
        this.appStore.toggleRights(right)
      }
    }

    public toggleTimezoneSetting(permanent: boolean) {
      let timezone = 'UTC'

      if (this.timezoneToggleAllowed) {
        timezone = this.prefsTimezoneSetting === 'UTC' ? 'USER' : 'UTC'
      }

      this.prefsStore.changeTimezoneSetting({
        timezone: timezone,
        permanent: permanent,
      })
    }

    public toggleGraphMode(permanent: boolean) {
      let mode: GraphMode = this.graphModePrefs === GraphMode.WebGL ? GraphMode.SVG : GraphMode.WebGL

      this.prefsStore.changeGraphMode({
        mode: mode,
        permanent: permanent,
      })
    }

    /**
     * Calculates minutes left until the session is expired and sets expiration in minutes
     */
    private setSessionExpiresInMinutes() {
      if (this.sessionExpirationTime) {
        let hourDiff = this.sessionExpirationTime - Date.now()

        this.sessionExpirationInMinutes = Math.max(0, parseInt((hourDiff / 60 / 1000).toFixed(0)))

        if (this.sessionExpirationInMinutes <= 0) {
          this.appStore.logout()
        }
      }
    }
  }
  export default toNative(App)
</script>

<style lang="scss" scoped>
  .env-test {
    border-bottom: 4px solid #4caf50;
  }

  .env-stage {
    border-bottom: 4px solid #9c27b0;
  }

  :deep(::-webkit-scrollbar-track:vertical) {
    box-shadow:
      1px 0 1px 0 #f6f6f6 inset,
      -1px 0 1px 0 #f6f6f6 inset;
  }

  :deep(::-webkit-scrollbar-track:horizontal) {
    box-shadow:
      0 1px 1px 0 #f6f6f6 inset,
      0 -1px 1px 0 #f6f6f6 inset;
  }

  :deep(::-webkit-scrollbar) {
    -webkit-appearance: none;
    width: 16px;
  }

  :deep(::-webkit-scrollbar-thumb) {
    background-clip: padding-box;
    background-color: #c1c1c1;
    border-color: transparent;
    border-radius: 9px 8px 8px 9px;
    border-style: solid;
    border-width: 3px 3px 3px 4px;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  :deep(::-webkit-scrollbar-thumb:hover) {
    background-color: rgba(0, 0, 0, 0.5);
  }

  :deep(.v-main) {
    height: 100%;
  }

  :deep(.search) {
    .v-field--variant-solo {
      margin-left: 4px;
      background: rgba(255, 255, 255, 0.15) !important;
    }

    input {
      font-size: 1.1rem;
      line-height: 2rem;
      font-weight: 400;
      color: white !important;
    }
  }

  :deep(.v-combobox .v-combobox__selection) {
    align-self: center;
    color: white !important;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
  }

  :deep(.container) {
    max-width: none;
  }

  :deep(.headline),
  :deep(.overline) {
    color: #e91e63;
    margin-bottom: 0 !important;
  }

  :deep(.v-dialog) {
    .overline {
      margin-left: -8px !important;
    }
  }

  :deep(.v-card__title) {
    .overline {
      margin-left: 8px !important;
    }
  }

  :deep(.v-window) {
    overflow: visible;
  }

  :deep(.v-footer) {
    opacity: 1;
    color: #fff;

    &.local {
      background: rgba(0, 0, 0, 0.8);
    }
    &.preview {
      background: rgba(233, 30, 99, 0.8);
    }
    &.testing {
      background: rgba(233, 30, 99, 0.8);
    }
    &.staging {
      background: rgba(233, 30, 99, 0.8);
    }
  }

  :deep(.v-app-bar) {
    .bar-item {
      max-width: 45%;

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  // BUG fix for vuetify dialog usage
  :deep(.v-card__actions) {
    background: white;
    z-index: 10;
  }

  :deep(.v-progress-linear) {
    &.global {
      position: fixed;
      z-index: 10;
    }
  }

  :deep(.reversed.v-switch .v-selection-control) {
    display: flex;
    flex-direction: row-reverse;

    .v-label {
      padding-inline-start: 0;
      padding-right: 10px;
    }
  }

  :deep(.v-list-item--active) {
    color: #e91e63;
  }

  :deep(header .v-app-bar) {
    padding: 10px;
  }
  :deep(.activeSelected) {
    color: #82b1ff;
  }

  :deep(.v-toolbar-title) {
    flex: 0 1 auto;
  }

  .bottomAlert {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
  }

  .innerFooter {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
  }
</style>
