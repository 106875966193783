import { AxiosResponse } from 'axios'

import { TeamsOrgSubscription, TeamsPlan } from '#types'

export function responseHandler({
  onError,
  onSuccess,
}: {
  onError: (error: string) => void
  onSuccess: (response: AxiosResponse) => void
}) {
  return (response: any) => {
    if (!response || response.status >= 400) {
      const message = response?.error?.title
        ? `Error: ${response?.error?.title}. Details: ${response?.error?.message || '-'}.`
        : response?.error?.message || 'Unknown server error occured, please report this'

      return onError(message)
    }

    return onSuccess(response)
  }
}

const PLANS: { [dataName: string]: string } = {
  legacy_teams: 'Legacy',
  teams: 'Teams',
  hrm: 'HRM',
}

export function formatPlanName(plan: TeamsPlan, subscription?: TeamsOrgSubscription) {
  return subscription?.status === 'trial' ? `${PLANS[plan.name]} – Trial` : PLANS[plan.name]
}

export const planOptions = Object.entries(PLANS).map(([dataName, displayName]) => ({
  text: displayName,
  value: dataName,
}))

export const subscriptionStatuses = [
  { text: 'Trial', value: 'trial' },
  { text: 'Paid', value: 'active' },
  { text: 'Waived', value: 'waived' },
]

export const ouraTestingExternalId: string = 'Cust-036564'

export const orgTypes = [
  {
    text: 'Oura Teams',
    value: 'coach',
  },
  {
    text: 'Oura Teams (Research)',
    value: 'research',
  },
  {
    text: '(Internal Use Only) Health Risk Management (Non-Anonmyous)',
    value: 'illness_detection',
  },
]

export const orgTypesListNames = {
  coach: 'Oura Teams',
  research: 'Oura Teams (Research)',
  health_private: 'Health Risk Management',
  illness_detection: 'Health Risk Management (Non-Anonymous)',
}

export const teamsHeaders = [
  {
    title: 'Name',
    key: 'name',
    sortable: true,
  },
  {
    title: 'Type',
    key: 'type',
  },
  {
    title: 'Account Manager',
    key: 'accountManagerEmail',
  },
  {
    title: 'Internal Test / Personal',
    key: 'externalId',
  },
  {
    title: 'Coaches',
    key: 'coachCount',
  },
  {
    title: 'Groups',
    key: 'groupCount',
  },
  {
    title: 'Members',
    key: 'memberCount',
  },
  {
    title: 'Status',
    key: 'subscriptionStatus',
  },
  {
    title: 'Subscription End Date',
    key: 'validTo',
  },
  {
    title: 'Created Date',
    key: 'createdAt',
  },
]

export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const organizationNameMinLength = 3
