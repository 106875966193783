<template>
  <v-progress-linear
    v-model="weightPercentage"
    data-cy="weight-bar"
    :absolute="false"
    :indeterminate="false"
    :color="color"
    :height="10"
    class="mt-2 mb-2"
  />
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  @Component({})
  class GraphWeightBar extends Vue {
    @Prop() public weightPercentage: number = 0

    public get color() {
      if (this.weightPercentage <= 33) {
        return 'green'
      } else if (this.weightPercentage >= 33 && this.weightPercentage <= 66) {
        return 'yellow'
      } else {
        return 'red'
      }
    }
  }
  export default toNative(GraphWeightBar)
</script>

<style scoped lang="scss"></style>
