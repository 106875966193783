import { Store } from 'pinia-class-component'

import { type DocumentReference, doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'

import { GroupScope } from '#views/members/constants'

import { AppStore } from '#stores/app'
import { BaseStore } from '#stores/base'

import { CustomGroup, CustomGroupResponse } from '#types/troubleshooter'

@Store()
export class CustomGroupStore extends BaseStore {
  private _customGroups: CustomGroup[] = []
  private _globalCustomGroups: CustomGroup[] = []

  public get customGroups(): CustomGroup[] {
    return this._customGroups
  }

  public get globalCustomGroups(): CustomGroup[] {
    return this._globalCustomGroups
  }

  public async fetchCustomGroups() {
    const customGroupData = await this.fetchCustomGroupData(GroupScope.PRIVATE)
    this._customGroups = customGroupData.customGroups

    const globalCustomGroupData = await this.fetchCustomGroupData(GroupScope.GLOBAL)
    this._globalCustomGroups = globalCustomGroupData.customGroups
  }

  public async createCustomGroup(
    groupName: string,
    selectedGraphs: string[],
    scope: GroupScope,
  ): Promise<CustomGroupResponse> {
    const customGroupData = await this.fetchCustomGroupData(scope)
    const currentGroups = customGroupData.customGroups
    const docRef = customGroupData.docRef

    const groupExists = currentGroups.map((group) => group.name).includes(groupName)
    if (groupExists) {
      return {
        success: false,
        message: 'Group already exists',
      }
    }

    let orderNumber = 0
    if (currentGroups.length) {
      orderNumber = Math.max(...currentGroups.map((x: any) => x.orderNumber)) + 1
    }
    const group: CustomGroup = {
      name: groupName,
      graphs: selectedGraphs,
      orderNumber,
    }
    currentGroups.push(group)
    return await this.sendCustomGroupData(docRef, currentGroups, 'Custom group created successfully', scope)
  }

  public async updateGroups(groups: CustomGroup[], scope: GroupScope): Promise<CustomGroupResponse> {
    const customGroupData = await this.fetchCustomGroupData(scope)
    const docRef = customGroupData.docRef

    return await this.sendCustomGroupData(docRef, groups, '', scope)
  }

  public async deleteCustomGroup(groupName: string, scope: GroupScope): Promise<CustomGroupResponse> {
    const customGroupData = await this.fetchCustomGroupData(scope)
    const currentGroups = customGroupData.customGroups
    const docRef = customGroupData.docRef

    const newGroups = currentGroups.filter((group) => group.name !== groupName)
    return await this.sendCustomGroupData(docRef, newGroups, 'Custom group deleted successfully', scope)
  }

  private async fetchCustomGroupData(scope: GroupScope): Promise<{
    docRef: DocumentReference
    customGroups: CustomGroup[]
  }> {
    let docRef: DocumentReference
    switch (scope) {
      case GroupScope.PRIVATE:
        docRef = this.getPrivateDoc()
        break
      case GroupScope.GLOBAL:
        docRef = this.getGlobalDoc()
        break
    }

    const snapshot = await getDoc(docRef)
    const groups: CustomGroup[] = snapshot.data()?.groups || []
    return {
      docRef: docRef,
      customGroups: groups,
    }
  }

  private getPrivateDoc(): DocumentReference {
    const appStore = new AppStore()
    const darwinUser = appStore.user
    return doc(getFirestore(), 'troubleshooterCustomGroups', `${darwinUser.id}`)
  }

  private getGlobalDoc(): DocumentReference {
    return doc(getFirestore(), 'troubleshooterGlobalCustomGroups', 'global')
  }

  private async sendCustomGroupData(
    docRef: DocumentReference,
    customGroups: CustomGroup[],
    message: string,
    scope: GroupScope,
  ): Promise<CustomGroupResponse> {
    return await setDoc(
      docRef,
      {
        groups: customGroups,
      },
      { merge: true },
    )
      .then(() => {
        switch (scope) {
          case GroupScope.GLOBAL: {
            this._globalCustomGroups = customGroups
            break
          }
          case GroupScope.PRIVATE: {
            this._customGroups = customGroups
            break
          }
        }
        return {
          success: true,
          message: message,
        }
      })
      .catch((error) => {
        return {
          success: false,
          message: error.message,
        }
      })
  }
}
