<template>
  <v-select
    data-cy="period-selection"
    :items="quickTimeSelections"
    return-object
    item-title="name"
    hide-details
    @update:model-value="$emit('select', $event?.value)"
  />
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { quickTimeFields } from '#views/members/constants'

  @Component({ emits: ['select'] })
  class PeriodPicker extends Vue {
    public quickTimeSelections = quickTimeFields
  }
  export default toNative(PeriodPicker)
</script>

<style scoped lang="scss"></style>
