<template>
  <div class="pa-0 border-t-sm">
    <v-card-title v-if="diagnosticsGroup.title" @click="toggleExpand()">
      <v-row class="flex-nowrap">
        <v-col class="flex-grow-1 flex-shrink-1 alert-text">
          <span class="text-overline">{{ diagnosticsGroup.title }} ({{ diagnosticCount }})</span>
        </v-col>
        <v-col class="flex-shrink-0 flex-grow-0 mr-n1" style="text-align: right" cols="2">
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-icon v-bind="props" size="medium" color="info">
                {{ expanded ? 'mdi-unfold-less-horizontal' : 'mdi-unfold-more-horizontal' }}
              </v-icon>
            </template>
            {{ expanded ? 'Hide diagnostics' : 'Show diagnostics' }}
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <div v-if="diagnosticsGroup.diagnostics && expanded">
      <DiagnosticInfos
        v-for="diagnostic of diagnosticsGroup.diagnostics"
        :key="diagnostic.diagnosticId"
        :diagnostic="diagnostic"
        :sidecar="sidecar"
        :show-hidden-diagnostics="showHiddenDiagnostics"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { DiagnosticGroupData } from '#types'

  @Component
  class DiagnosticGroup extends Vue {
    @Prop() public diagnosticsGroup!: DiagnosticGroupData
    @Prop() public sidecar!: boolean
    @Prop() public showHiddenDiagnostics!: boolean
    public expanded: boolean = false

    public toggleExpand() {
      this.expanded = !this.expanded
    }

    public mounted() {
      if (this.diagnosticsGroup.expanded) {
        this.expanded = this.diagnosticsGroup.expanded
      }
    }

    public get diagnosticCount(): number {
      return this.diagnosticsGroup.diagnostics.length
    }
  }

  export default toNative(DiagnosticGroup)
</script>
