import { AppStore } from './app'
import { UserStore } from './user'

import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

import { DataFile, FileDownloadPreference, GetFileUrlRequest } from '#types'

const dataFiles = [
  {
    bucket: 'user-files',
    prefix: '',
  },
  {
    bucket: 'ring-stream',
    prefix: 'latest-results/',
  },
  {
    bucket: 'ring-stream',
    prefix: 'stream-full/',
  },
  {
    bucket: 'archive',
    prefix: 'v1/latest_results/',
  },
]

const REQUEST_SOURCE_LIST_ORB_STREAMS = 'listOrbStreams'

@Store()
export class FilesStore extends BaseStore {
  public dataWait = false
  private listFilesInProgress = false

  public files: DataFile[] = []

  public summary: any = null
  public orbStreams: any[] = []

  // List of files (DataFile.key) user has chosen to download sensitive data version.
  public sensitiveDownloadChoices: string[] = []

  public waitingForListOrbStreams(): boolean {
    return this.waitingForData([REQUEST_SOURCE_LIST_ORB_STREAMS])
  }

  public async listFiles(uuid: string) {
    /**
     * This function cannot be called until previous invocation has finished.
     */
    if (!this.listFilesInProgress) {
      this.listFilesInProgress = true
    } else {
      return
    }

    const userStore = new UserStore()
    const appStore = new AppStore()

    const files: DataFile[] = []
    this.files = []
    this.dataWait = true
    const env = appStore.cloudEnv

    if (env) {
      for (const file of dataFiles) {
        const response = await this.makeRequest(
          {
            method: 'get',
            url: `/api/v1/files?environment=${env}&search_prefix=${file.prefix}${uuid}&bucket=${file.bucket}`,
          },
          'listFiles_' + file.bucket + '_' + file.prefix,
          uuid,
          userStore.user?.uuid,
        ).catch((_axiosError: AxiosError) => {
          //ignore for now
          return null
        })
        if (response?.data?.contents) {
          response.data?.contents.map((content: any) =>
            files.push({
              ...content,
              bucket: file.bucket,
              env: env,
            }),
          )
        }
      }
    }
    this.dataWait = false
    this.files = files
    this.listFilesInProgress = false
  }

  public async getSummary(path: string) {
    this.summary = null
    this.dataWait = true
    const response = await this.makeRequest({ method: 'get', url: path }, 'getSummary').catch(
      (_axiosError: AxiosError) => {
        //ignore for now
        return null
      },
    )
    this.dataWait = false
    this.summary = response?.data?.contents
  }

  public async downloadFile(file: DataFile) {
    const appStore = new AppStore()
    this.dataWait = true
    const payload: GetFileUrlRequest = {
      bucket: file.bucket,
      save_as: file.name ?? '',
      key: [file.key],
      environment: appStore.cloudEnv,
      anonymizeJzlog: file.anonymizeJzlog,
    }
    const response = await this.makeRequest(
      { method: 'post', url: '/api/v1/files/url', data: payload },
      'downloadFile',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.dataWait = false
    if (response?.data?.fileUrl) {
      window.open(response?.data?.fileUrl, '_blank')
    }
  }

  public async listOrbStreams(uuid: string) {
    this.updateDataWait({ source: REQUEST_SOURCE_LIST_ORB_STREAMS, wait: true })
    const userStore = new UserStore()

    this.orbStreams = []
    const response = await this.makeRequest(
      {
        method: 'get',
        url: `/api/v1/users/${uuid}/orb-streams`,
      },
      REQUEST_SOURCE_LIST_ORB_STREAMS,
      uuid,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_LIST_ORB_STREAMS)
    })
    this.updateDataWait({ source: REQUEST_SOURCE_LIST_ORB_STREAMS, wait: false })
    this.orbStreams = response?.data?.streams
  }

  public updateDownloadPreference(downloadPreferences: FileDownloadPreference) {
    const index = this.sensitiveDownloadChoices.indexOf(downloadPreferences.file.key)

    if (index > -1) {
      // sensitive data download choice found from state, removing
      if (!downloadPreferences.downloadSensitiveData) {
        this.sensitiveDownloadChoices.splice(index, 1)
      }
    } else {
      // sensitive data download choice not found from thiss, adding
      if (downloadPreferences.downloadSensitiveData) {
        this.sensitiveDownloadChoices.push(downloadPreferences.file.key)
      }
    }
  }
}
