<template>
  <v-list class="pa-0">
    <draggable
      :list="customGroups"
      :group="scope"
      item-key="name"
      animation="200"
      :disabled="!draggable"
      @change="reNumberGroups()"
    >
      <template #item="{ element }">
        <div
          :class="{ 'horizontal-layout': horizontal, 'vertical-layout': !horizontal }"
          data-cy="troubleshooter-custom-groups-list"
        >
          <v-list-item
            :key="element.name"
            class="ma-1 flex-grow-1"
            rounded="lg"
            elevation="1"
            :variant="scope === 'global' ? 'text' : 'tonal'"
            density="compact"
            :active="isGroupSelected(element)"
            :disabled="!canSelectGroup(element).allow"
            @click="selectGraphs(element)"
          >
            <div class="d-flex">
              <v-icon v-if="draggable" icon="mdi-drag" />
              <span class="align-self-end">{{ element.name }}</span>
            </div>
          </v-list-item>
          <v-btn
            v-if="closable"
            data-cy="troubleshooter-delete-group"
            rounded="lg"
            icon="mdi-trash-can-outline"
            @click="selectGroupForDeletion(element)"
          />
          <v-btn v-if="!closable && showIcons" rounded="lg" icon="mdi-lock" />
          <v-tooltip v-if="!canSelectGroup(element).allow" activator="parent" location="bottom">
            {{ canSelectGroup(element).reason }}
          </v-tooltip>
        </div>
      </template>
    </draggable>
  </v-list>
  <ConfirmationDialog
    v-model="showDeleteDialog"
    title="Are you sure you want to delete this group?"
    :close-callback="close"
    :disable-confirm="disableConfirm"
    @confirm="deleteGroup()"
  >
    <v-container v-if="selectedGroupForDeletion">
      <v-row>
        <v-col>
          <strong>Group name:</strong>
          {{ selectedGroupForDeletion.name }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-chip v-for="graph in selectedGroupForDeletion.graphs" :key="graph" class="mr-2 mb-2">
            {{ graph }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row v-if="statusResponse">
        <v-col>
          <v-alert :type="statusResponse.success ? 'success' : 'error'">
            {{ statusResponse.message }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </ConfirmationDialog>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { GroupScope } from '#views/members/constants'

  import { TroubleshooterStore } from '#stores'
  import { CustomGroupStore } from '#stores/customGroups'

  import { CustomGroup, CustomGroupResponse, SelectionGrant } from '#types/troubleshooter'

  @Component({})
  class CustomGroupsList extends Vue {
    @Prop({
      default: false,
    })
    public closable: boolean = false
    @Prop({
      default: false,
    })
    public draggable: boolean = false
    @Prop({
      default: false,
    })
    public horizontal: boolean = false
    @Prop({
      default: true,
    })
    public showIcons: boolean = true
    @Prop({
      default: GroupScope.PRIVATE,
    })
    public scope: GroupScope = GroupScope.PRIVATE
    public troubleshooterStore = new TroubleshooterStore()
    public customGroupStore = new CustomGroupStore()
    public showDeleteDialog: boolean = false
    public selectedGroupForDeletion: CustomGroup | null = null
    public statusResponse: CustomGroupResponse | null = null
    public disableConfirm = false

    public isGroupSelected(customGroup: CustomGroup): boolean {
      return (
        customGroup.graphs.length != 0 &&
        customGroup.graphs.every((key) => this.troubleshooterStore.selectedGraphsMap.has(key))
      )
    }

    public canSelectGroup(customGroup: CustomGroup): SelectionGrant {
      return this.troubleshooterStore.canSelectGroup(customGroup.graphs)
    }

    public get customGroups() {
      switch (this.scope) {
        case GroupScope.PRIVATE:
          return this.customGroupStore.customGroups
        case GroupScope.GLOBAL:
          return this.customGroupStore.globalCustomGroups
        default:
          return this.customGroupStore.customGroups
      }
    }

    public reNumberGroups() {
      this.customGroups.forEach((group, index) => {
        group.orderNumber = index
      })
      this.customGroupStore.updateGroups(this.customGroups, this.scope)
    }

    public selectGraphs(customGroup: CustomGroup) {
      if (this.isGroupSelected(customGroup)) {
        this.troubleshooterStore.deSelectGraphKeys(customGroup.graphs)
      } else {
        this.troubleshooterStore.selectGraphKeys(customGroup.graphs)
      }
    }

    public selectGroupForDeletion(group: CustomGroup) {
      this.selectedGroupForDeletion = group
      this.showDeleteDialog = true
    }

    public async deleteGroup() {
      if (this.selectedGroupForDeletion) {
        this.statusResponse = await this.customGroupStore.deleteCustomGroup(
          this.selectedGroupForDeletion.name,
          this.scope,
        )
        if (this.statusResponse.success) {
          this.disableConfirm = true
        }
      }
    }

    public close() {
      this.statusResponse = null
      this.disableConfirm = false
    }

    public async mounted() {
      await this.customGroupStore.fetchCustomGroups()
    }
  }

  export default toNative(CustomGroupsList)
</script>

<style scoped lang="scss">
  .horizontal-layout {
    display: inline-flex;
  }
  .vertical-layout {
    display: flex;
  }
</style>
