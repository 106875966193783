<template>
  <v-list flat cols="4">
    <v-list-item-title class="text-primary">CATEGORIES</v-list-item-title>
    <v-list-item
      v-for="category in categories"
      :key="category.name"
      data-cy="category-item"
      class="text-body-1"
      @click="selectCategory(category)"
    >
      {{ category.name }}
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { TroubleshooterStore } from '#stores'

  import { TroubleshooterCategory } from '#types/troubleshooter'

  @Component({})
  class CategorySelection extends Vue {
    private troubleshooterStore = new TroubleshooterStore()

    public get categories() {
      return this.troubleshooterStore.categories
    }

    public selectCategory(category: TroubleshooterCategory) {
      this.troubleshooterStore.setSelectedCategory(category)
    }
  }
  export default toNative(CategorySelection)
</script>

<style scoped lang="scss"></style>
